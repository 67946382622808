import { useLogout as useCorraLogout } from '@corratech/pylot-auth-manager'

type UseLogoutReturn = { logout: () => Promise<void> }

export const UseLogout = (): UseLogoutReturn => {
    const { logout } = useCorraLogout();
    return {
        logout: async () => {
            window.localStorage.removeItem('COGNITO_ACCESS_TOKEN')
            window.localStorage.removeItem('COGNITO_PORTFOLIO_USER_EMAIL')
            window.localStorage.removeItem('COGNITO_SUB')
            await logout();
        }
    }
}